import { CustomNavbar } from "../Navbar/CustomNavbar";

export const Header = () => {
  return (
    <div>
      <CustomNavbar></CustomNavbar>
      <div className="header">
        <div className="header__text">
          <div className="font-weight-bold">
            <p>India's Largest</p>
            <h1 className="header__title">Sustainable Packaging Platform</h1>
            <p>
              We enable hassle-free, hygienic, and more sustainable food
              operations
            </p>
          </div>
        </div>

        <div className="header__gallery">
          <div className="header__gallery-img">
            <img src="/assets/img/home-screen-five.png" alt="header-5"></img>
          </div>
          <div className="header__gallery-img">
            <img src="/assets/img/home-screen-four.png" alt="header-4"></img>
          </div>
          <div className="header__gallery-img">
            <img src="/assets/img/home-screen-three.png" alt="header-3"></img>
          </div>
          <div className="header__gallery-img">
            <img src="/assets/img/home-screen-two.png" alt="header-2"></img>
          </div>
          <div className="header__gallery-img">
            <img src="/assets/img/home-screen-one.png" alt="header-1"></img>
          </div>
          <div className="header__gallery-img">
            <img src="/assets/img/home-screen-six.png" alt="header-6"></img>
          </div>
        </div>
      </div>
    </div>
  );
};
