import { Button } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
export const Services = () => {
  return (
    <div className="services">
      <div className="services__title">
        <h1>I am looking for</h1>
      </div>
      <div className="services__service-card services__card-1">
        <div className="services__card-title"></div>
        <div className="services__image-container-1">
          <img src="assets/img/food-package-one.png" alt="Food Packaging" />
        </div>
        <div className="services__image-container-2">
          <Link to="food-packaging-services">
            <Button
              className="services__image-button"
              variant="outline-success"
            >
              Food Packaging Service
            </Button>
          </Link>
          <img src="assets/img/food-package-two.png" alt="packaging" />
        </div>
      </div>

      <div className="services__service-card services__card-2">
        <div className="services__card-title"></div>
        <div className="services__image-container-1">
          <img
            src="assets/img/software-service-one.png"
            alt="Software service"
          />
        </div>
        <div className="services__image-container-3">
          <Link to="software-services">
            <Button
              className="services__image-button-2"
              variant="outline-success"
              // onClick={handleContactUsButtonClick}
            >
              Software Service
            </Button>
          </Link>
          <img
            src="assets/img/software-service-twoo.png"
            alt="software service"
          />
        </div>
      </div>
    </div>
  );
};
