import { CustomNavbar } from "../../components/Navbar/CustomNavbar";

const blogData = [
  {
    img: "/assets/img/circularEconomy.jpeg",
    text: "Circular economy via the lens of Covid-19",
    date: "Jan 26, 2021",
    link: "https://medium.com/@getinfinitybox/circular-economy-via-the-lens-of-covid-19-6b05abde090d",
  },
  {
    img: "/assets/img/plasticForAllProblems.jpeg",
    text: "Can we really blame plastic for all the problems?",
    date: "Jan 26, 2021",
    link: "https://medium.com/@getinfinitybox/circular-economy-via-the-lens-of-covid-19-6b05abde090d",
  },
  {
    img: "/assets/img/millenials.png",
    text: "Is there nothing else Millennials can do to protect the environment besides opting out of cutlery?",
    date: "Jan 26, 2021",
    link: "https://medium.com/@getinfinitybox/is-there-nothing-else-millennials-can-do-to-protect-the-environment-besides-opting-out-of-cutlery-8e49241b628b",
  },
  {
    img: "/assets/img/indianMillenials.gif",
    text: "Are Indian millennials fed-up of the heaps of plastic in their houses?",
    date: "Jan 26, 2021",
    link: "https://medium.com/@getinfinitybox/are-indian-millennials-fed-up-of-the-heaps-of-plastic-in-their-houses-d29bdd9b6e34",
  },
  {
    img: "/assets/img/infinityBoxhub.gif",
    text: "India to join single-use plastic free club and InfinityBox is there to contribute towards it !!",
    date: "Jan 26, 2021",
    link: "https://medium.com/@getinfinitybox/india-to-join-single-use-plastic-free-club-and-infinitybox-is-there-to-contribute-towards-it-8f2be80096c2",
  },
  {
    img: "/assets/img/plasticAproblem.gif",
    text: "Globally countries are imposing a ban on single-use plastic",
    date: "Jan 26, 2021",
    link: "https://medium.com/@getinfinitybox/globally-countries-are-imposing-a-ban-on-single-use-plastic-2413ed862fed",
  },
  {
    img: "/assets/img/reducePlastic.gif",
    text: "Reducing waste production!",
    date: "Jan 26, 2021",
    link: "https://medium.com/@getinfinitybox/reducing-waste-production-796722b33e3f",
  },
  {
    img: "/assets/img/circularEco.gif",
    text: "Circular Economy is the path to save our future!",
    date: "Jan 26, 2021",
    link: "https://medium.com/@getinfinitybox/circular-economy-is-the-path-to-save-our-future-b907a9f5db6a",
  },
  {
    img: "/assets/img/howItBegan.png",
    text: "How did it all begin?",
    date: "Jan 26, 2021",
    link: "https://medium.com/@getinfinitybox/how-did-it-all-begin-46376c926fbd",
  },
];

function OurProductDetailsScreen(props) {
  // const location = useLocation();
  const title = props.location.state.title;
  const img = props.location.state.img;
  let product;
  img.forEach((element) => {
    if (element.title === title) {
      product = element;
    }
  });

  return (
    <div className="product-details">
      <CustomNavbar />
      <div className="product-screen-class">
        <div className="product-list">
          <div className="product-tile">
            <img src={product.imgOne} alt="product-one" />
            <h3>{product.title}</h3>
          </div>
          <div className="product-tile">
            <img src={product.imgTwo} alt="product-two" />
            <h3>{product.imgOnename}</h3>
          </div>
          <div className="product-tile">
            <img src={product.imgThree} alt="product-three" />
            <h3>{product.imgThreeName}</h3>
          </div>
          {product.imgFour && (
            <>
              <div className="product-tile">
                <img src={product.imgFour} alt="product-four" />
                <h3>{product.imgFourName}</h3>
              </div>
              <div className="product-tile">
                <img src={product.imgFive} alt="product-five" />
                <h3>{product.imgFiveName}</h3>
              </div>
              <div className="product-tile">
                <img src={product.imgSix} alt="product-six" />
                <h3>{product.imgSixName}</h3>
              </div>
            </>
          )}
          {product.imgSeven && (
            <>
              <div className="product-tile">
                <img src={product.imgSeven} alt="product-seven" />
                <h3>{product.imgSevenName}</h3>
              </div>
              <div className="product-tile">
                <img src={product.imgEight} alt="product-eight" />
                <h3>{product.imgEightName}</h3>
              </div>
              <div className="product-tile">
                <img src={product.imgNine} alt="product-nine" />
                <h3>{product.imgNineName}</h3>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default OurProductDetailsScreen;
