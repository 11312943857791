import { Button, Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { NavItem } from "../NavItem/NavItem";
export const CustomNavbar = () => {
  return (
    <Navbar
      className="shadow-sm font-weight-bold p-2 text-uppercase"
      fixed="top"
      bg="light"
      expand="lg"
    >
      <Container>
        <Navbar.Brand className="mx-auto" href="#home">
          <Container className="navbar--logo">
            <Link to="/">
              {" "}
              <img src="/assets/img/InfinityLogo.png" alt="logo"></img>
            </Link>
          </Container>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <NavItem>
              <Link className="navlink" to="/">
                Home
              </Link>
            </NavItem>
            <NavItem>
              <Link className="navlink" to="about">
                About Us
              </Link>
            </NavItem>
            <NavItem>
              <Link className="navlink" to="services">
                Our Services
              </Link>
            </NavItem>
            <NavItem>
              <Link className="navlink" to="blogs">
                Blogs
              </Link>
            </NavItem>
            <NavItem>
              <Link className="navlink" to="faq">
                FAQs
              </Link>
            </NavItem>
          </Nav>
          <a href="#contact" className="navlink" to="about">
            <Button
              style={{
                height: "40px",
                alignSelf: "center",
                marginLeft: "30px",
                fontFamily: "Poppins",
              }}
              variant="outline-success"
              // onClick={handleContactUsButtonClick}
            >
              CONTACT SALES
            </Button>
          </a>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
