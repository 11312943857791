import React from "react";

export const BlogCard = ({ idx, img, text, date, link }) => {
  return (
    <div className={idx % 2 !== 0 ? "blogCard blogCard__right" : "blogCard"}>
      <a target="_blank" rel="noreferrer" href={link}>
        <div className="blogCard__img">
          <img src={img} alt="blog" width={250} height={250} />
        </div>
        <div className="blogCard__caption">
          <div className="blogCard__text">
            <span>{text}</span>
          </div>
          <div className="blogCard__date">
            <span>{date}</span>
          </div>
        </div>
      </a>
    </div>
  );
};
