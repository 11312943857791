export const SoftwareServices = () => {
  return (
    <section className="software-services">
      <div className="software-services__title">
        <div className="software-services__heading">
          Streamline Your Operations with Our SaaS Solutions
        </div>
        <div className="software-services__subheading">
          Our cutting-edge SaaS products are designed to simplify and optimize
          your day-to-day operations. With our innovative technology. You can
          focus on growing your business while we handle the complexities behind
          the scenes.
        </div>
      </div>
      <div className="product-item">
        <div className="product-item__content">
          <h4>Custom Development Projects</h4>
          <ul className="product-item__details">
            We offer custom development projects tailored to your needs. Whether
            you need additional features, integrations with existing systems, or
            entirely new software solutions, our experienced team can turn your
            vision into reality.
          </ul>
        </div>
        <div className="product-item__img">
          <img src="/assets/img/Custom Project.png" alt="project"></img>
        </div>
      </div>
      <div className="product-item">
        <div className="product-item__content">
          <h4>Purchase Order and Invoicing</h4>
          <ul>
            <li>
              Effortlessly create, manage, and track purchase orders, ensuring
              smooth transactions with your suppliers
            </li>
            <li>
              generate accurate and professional invoices, helping you get paid
              faster.
            </li>
            <li>
              Say goodbye to paperwork and hello to efficiency with our Purchase
              Order and Invoicing software
            </li>
            <li>Create, manage, and track purchase orders</li>
            <li>Automated invoicing and payment reminders</li>
            <li>Automated invoicing and payment reminders</li>
          </ul>
        </div>
        <div className="product-item__img">
          <img src="/assets/img/purchase-invoice1.png" alt="invoice"></img>
        </div>
      </div>

      <div className="product-item">
        <div className="product-item__content">
          <h4>Meal Subscription Managing Software</h4>
          <ul>
            <li>
              With Our Meal Subscription Managing software focus on providing
              exceptional culinary experiences to your customers.
            </li>
            <li>
              From personalized meal plans to seamless order management, our
              software handles it all.
            </li>
            <li>Customizable meal plans and dietary preferences</li>
            <li>Automated subscription management and order processing</li>
            <li>Delivery route optimization for efficient logistics</li>
            <li>Customer engagement and feedback tools</li>
          </ul>
        </div>
        <div className="product-item__img">
          <img src="/assets/img/food-subscription.png" alt="subscription"></img>
        </div>
      </div>
    </section>
  );
};
