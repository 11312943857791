export const AboutUsHeader = () => {
  return (
    <>
      <div className="about-us__section-1">
        <div className="about-us__heading">
          <h1>
            <span className="about-us__custom-text-color">Who</span> are we?
          </h1>
          <p>
            At InfinityBox, we're transforming the food packaging landscape. As
            India's largest provider of reusable service ware, we offer
            convenient, eco-friendly solutions to businesses. Our agnostic
            approach accommodates various materials, from melamine to PP to Bone
            china, tailored to client preferences. We estimate daily plate
            consumption, deliver in advance, collect used items with food waste,
            and handle end to end washing and delivery. By simplifying the
            process, we relieve clients of managing service ware, allowing them
            to focus on their core operations, while reducing their costs and
            their environmental footprint!
            <br></br>Join us in our mission towards a greener future. Discover
            the InfinityBox advantage today.
          </p>
        </div>
        <div className="about-us__img">
          <img src="assets/img/growth.png" loading="lazy" alt="about us"></img>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="about-us__section-2">
        <div className="about-us__heading">
          <h1>
            <span className="about-us__custom-text-color">What</span> drives us?
          </h1>
          <p>
            At InfinityBox, we are dedicated to reducing your expenses and
            operational hassle while championing environmental consciousness.
            Our reusable packaging service not only streamlines operations but
            also promotes a greener future. Experience seamless sustainability
            and cost savings with InfinityBox.
          </p>
        </div>
        <div className="about-us__img">
          <img src="assets/img/startup.png" loading="lazy" alt="about us"></img>
        </div>
      </div>
    </>
  );
};
