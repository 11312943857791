import { faCheckSquare } from "@fortawesome/free-regular-svg-icons";
import {
  faLayerGroup,
  faRecycle,
  faSnowflake,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

export const PackagingProducts = () => {
  return (
    <div className="packaging-products">
      <div className="packaging-products__products-container"></div>

      <ProductsGalleryAndText />
      <ServicesSection />
      <EventsSection />
      <Advantages />
      <Hygiene />
    </div>
  );
};

const Hygiene = () => {
  return (
    <section className="hygiene">
      <div className="hygiene__text">
        <h2>Hygiene</h2>
        <br />
        <p>
          Hygienic handling of food and beverages is particularly important in
          the catering industry. That is why we at Vytal, together with our
          suppliers, have invested a lot of work in designing our containers so
          that they are easy to wash. This enables us to offer our partners
          containers that can be washed according to the applicable hygiene
          standards and are sustainable at the same time.
        </p>

        <div className="hygiene__tests">
          <span className="hygiene__icon">
            <FontAwesomeIcon icon={faCheckSquare} size="2x" />
          </span>
          <span className="hygiene__icon">Anti microbial Testing</span>
        </div>
        <div className="hygiene__tests">
          <span className="hygiene__icon">
            <FontAwesomeIcon icon={faCheckSquare} size="2x" />
          </span>
          <span className="hygiene__icon">Hard metal testing</span>
        </div>
        <div className="hygiene__tests">
          <span className="hygiene__icon">
            <FontAwesomeIcon icon={faCheckSquare} size="2x" />
          </span>
          <span className="hygiene__icon">Migration Testing</span>
        </div>
        <div className="hygiene__tests">
          <span className="hygiene__icon">
            <FontAwesomeIcon icon={faCheckSquare} size="2x" />
          </span>
          <span className="hygiene__icon">Hard Water Testing</span>
        </div>
      </div>
      <div className="hygiene__img">
        <img src="assets/img/dishwasher.png" alt="dishwasher"></img>
      </div>
    </section>
  );
};

const ServicesSection = () => {
  return (
    <div className="products__gallery-and-text">
      <div className="products__text">
        <h2>End to end packaged service </h2>
        <p>
          Full day's stock of clean and sanitized service ware in the morning,
          collect it, and replace it with stock for the next day
        </p>
        <a href="#contact"> {"Contact our sales team now -> "}</a>
      </div>
      <div className="products__service-illustration">
        <img
          src="/assets/img/service_illustration1.png"
          alt="illustration"
        ></img>
      </div>
    </div>
  );
};

const EventsSection = () => {
  return (
    <div className="products__gallery-and-text">
      <div className="products__text">
        <h2>Organize events smoothly</h2>
        <p>
          We have serviced events with all SKUs from Chaffin's dishes to
          melamine/bonechina plates and even frills
        </p>
        <a href="#contact"> {"Contact our sales team now -> "}</a>
      </div>
      <div className="products__service-illustration">
        <img src="/assets/img/events_illustration.png" alt="illustration"></img>
      </div>
    </div>
  );
};

const ProductsGalleryAndText = () => {
  return (
    <div className="products__gallery-and-text">
      <div className="products__text">
        <h2>Our Products</h2>
        <p>
          We can service all SKUs from Chaffin Dishes to plates to spoons. We
          procure and invest in inventory basis client's requirement
        </p>
        <Link to="products"> {"Know More ->"}</Link>
      </div>
      <div className="products__gallery">
        <div className="products__gallery-img">
          <img src="/assets/img/5-CP-black.png" alt="5cp"></img>
        </div>
        <div className="products__gallery-img">
          <img src="/assets/img/3-CP-Black.png" alt="3cp"></img>
        </div>
        <div className="products__gallery-img">
          <img src="/assets/img/6-CP.png" alt="6cp"></img>
        </div>
        <div className="products__gallery-img">
          <img src="/assets/img/8-CP.png" alt="8cp"></img>
        </div>
        <div className="products__gallery-img">
          <img src="/assets/img/bowl.png" alt="bowl"></img>
        </div>
      </div>
    </div>
  );
};

const Advantages = () => {
  return (
    <div className="products">
      <div className="products__container">
        <div className="products__list">
          <div className="products__list-item">
            <div className="products__icon">
              <img
                src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/noun-microwave.svg"
                alt="microwave"
              />
            </div>
            <div className="products__description">
              <div className="products__head">Microwave Safe</div>
            </div>
          </div>
          <div className="products__list-item">
            <div className="products__icon">
              <img
                src="https://infinity-box-website.s3.ap-south-1.amazonaws.com/build/assets/img/noun-dishwasher.svg"
                alt="microwave"
              />
            </div>
            <div className="products__description">
              <div className="products__head">Dishwasher Safe</div>
            </div>
          </div>
          <div className="products__list-item">
            <div className="products__icon">
              <FontAwesomeIcon icon={faSnowflake} />
            </div>
            <div className="products__description">
              <div className="products__head">Freezer Safe</div>
            </div>
          </div>
          <div className="products__list-item">
            <div className="products__icon">
              <FontAwesomeIcon icon={faRecycle} />
            </div>
            <div className="products__description">
              <div className="products__head">Reusable</div>
            </div>
          </div>
          <div className="products__list-item">
            <div className="products__icon">
              <FontAwesomeIcon icon={faLayerGroup} />
            </div>
            <div className="products__description">
              <div className="products__head">Ergonomic design</div>
            </div>
          </div>
          <div className="products__list-item">
            <div className="products__icon">
              <img src="/assets/img/bpaFree.svg" alt="microwave" />
            </div>
            <div className="products__description">
              <div className="products__head">BPA Free</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
