import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { Footer } from "../../components/Footer/Footer";
import { CustomNavbar } from "../../components/Navbar/CustomNavbar";

const imageGallery = [
  {
    title: "Bone China",
    imgOnename: "Bone China Matte Finish",
    imgTwoName: "Bone China Pure White",
    imgThreeName: "Bone China White",
    imgFourName: "Bone China Shinning",
    imgFiveName: "Bone China",
    imgSixName: "Bone China",
    imgOne: "/assets/img/Bone-China-Matte-finish.jpeg",
    imgTwo: "/assets/img/Bone-China-pure-white.jpeg",
    imgThree: "/assets/img/Bone-china-white.jpeg",
    imgFour: "/assets/img/Bone-China-white-shinning-edge.jpeg",
    imgFive: "/assets/img/BoneChina.jpeg",
    imgSix: "/assets/img/Bone-China.jpeg",
  },
  {
    title: "Chaffin Dish",
    imgOnename: "Cheffin Dishes Brass",
    imgTwoName: "Cheffin Dishes Black Brass",
    imgThreeName: "Cheffin Dishes Round Black Brass",
    imgOne: "/assets/img/Chefen-Dishes Brass.jpeg",
    imgTwo: "/assets/img/Chefen-Dishes-Black_Brass.jpeg",
    imgThree: "/assets/img/Chefen-Dishes-Brass-round.jpeg",
  },
  {
    title: "Melamine",
    imgOnename: "Melamin Blue Border",
    imgTwoName: "Melamine",
    imgThreeName: "Melamine",
    imgFourName: "Melamine Golden Border",
    imgFiveName: "Melamine white",
    imgSixName: "Melamin With Golden linings",
    imgOne: "/assets/img/Melamin-blue-border.jpeg",
    imgTwo: "/assets/img/Melamine.jpeg",
    imgThree: "/assets/img/Melamine.jpeg",
    imgFour: "/assets/img/Melamine-golden-border.jpeg",
    imgFive: "/assets/img/Melamine-white.jpeg",
    imgSix: "/assets/img/Melamin-with-golden-linings.jpeg",
  },

  {
    title: "Arcylic",
    imgOnename: "All PP",
    imgTwoName: "Curry Bowl round PP 4",
    imgThreeName: "Dinner Plate PP 4",
    imgFourName: "Dinner Plate PP",
    imgFiveName: "Curry Bowl PP",
    imgSixName: "Service Platter PP",
    imgOne: "/assets/img/all-PP.jpeg",
    imgTwo: "/assets/img/Curry-Bowl-round-PP-4.jpeg",
    imgThree: "/assets/img/Dinner-Plate-PP 4.jpeg",
    imgFour: "/assets/img/Dinner-Plate-PP.jpeg",
    imgFive: "/assets/img/CurryBowl-PP.jpeg",
    imgSix: "/assets/img/Service-Platter-PP.jpeg",
    imgSeven: "/assets/img/two-PP.jpeg",
    imgSevenName: "Two PP",
    imgEight: "/assets/img/color-PP.jpeg",
    imgEightName: "Color PP",
    imgNine: "/assets/img/Multi-colour-container.jpeg",
    imgNineName: "Multi Colour Container",
  },
  {
    title: "Arcyclic Compartmet Plates",
    imgOnename: "Plastic Blue Spoon Forks",
    imgTwoName: "5-CP-black",
    imgThreeName: "3-CP-Black",
    imgFourName: "6-CP",
    imgFiveName: "8-CP",
    imgSixName: "bowl",
    imgOne: "/assets/img/Plastic Blue Spoon Forks.jpeg",
    imgTwo: "/assets/img/5-CP-black.png",
    imgThree: "/assets/img/3-CP-Black.png",
    imgFour: "/assets/img/6-CP.png",
    imgFive: "/assets/img/8-CP.png",
    imgSix: "/assets/img/bowl.png",
    imgSeven: "/assets/img/3 CP with Lid photo.jpeg",
    imgSevenName: "3 CP with Lid photo",
    imgEight: "/assets/img/5 CP Round.jpeg",
    imgEightName: "5 Cp Round",
    imgNine: "/assets/img/Frosted Water Glass.jpeg",
    imgNineName: "Frosted Water Glass",
  },
];

export const OurProductsScreen = () => {
  return (
    <div className="our-products">
      <CustomNavbar />
      {imageGallery.map((imageDetails) => (
        <ProductCard
          imageGallery={imageGallery}
          title={imageDetails.title}
          imgOnename={imageDetails.imgOnename}
          imgOne={imageDetails.imgOne}
          imgTwoName={imageDetails.imgTwoName}
          imgTwo={imageDetails.imgTwo}
          imgThreeName={imageDetails.imgThreeName}
          imgThree={imageDetails.imgThree}
          imgFourName={imageDetails.imgFourName}
          imgFour={imageDetails.imgFour}
          imgFiveName={imageDetails.imgFiveName}
          imgFive={imageDetails.imgFive}
          imgSixName={imageDetails.imgSixName}
          imgSix={imageDetails.imgSix}
          imgSeven={imageDetails.imgSeven}
          imgSevenName={imageDetails.imgSevenName}
          imgEight={imageDetails.imgEight}
          imgEightName={imageDetails.imgEightName}
          imgNine={imageDetails.imgNine}
          imgNineName={imageDetails.imgNineName}
        />
      ))}
      <ContactUs />
      <Footer />
    </div>
  );
};

const ProductCard = ({
  imageGallery,
  title,
  imgOnename,
  imgOne,
  imgTwoName,
  imgTwo,
  imgThreeName,
  imgThree,
  imgFourName,
  imgFour,
  imgFiveName,
  imgFive,
  imgSixName,
  imgSix,
  imgSeven,
  imgSevenName,
  imgEight,
  imgEightName,
  imgNine,
  imgNineName,
}) => {
  return (
    <div className="product-card">
      <div className="product-card__description">
        <Link
          to={{
            pathname: "/products-details",
            state: { title: title, img: imageGallery },
          }}
        >
          <div className="product-card__title">{title}</div>
        </Link>
      </div>
      <div className="product-card__carousel">
        <Carousel>
          <Carousel.Item interval={2000}>
            <div className="product-card__main-content">
              <Link
                to={{
                  pathname: "/products-details",
                  state: { title: title, img: imageGallery },
                }}
              >
                <div className="product-card__image-container">
                  <div className="product-card__image-name-overlay">
                    <p>{imgOnename}</p>
                  </div>
                  <div className="product-card__my-image">
                    <img src={imgOne} alt=" One" />
                  </div>
                </div>
              </Link>
              <Link
                to={{
                  pathname: "/products-details",
                  state: { title: title, img: imageGallery },
                }}
              >
                <div className="product-card__image-container">
                  <div className="product-card__image-name-overlay">
                    <p>{imgTwoName}</p>
                  </div>
                  <div className="product-card__my-image">
                    <img src={imgTwo} alt=" Two" />
                  </div>
                </div>
              </Link>
              <Link
                to={{
                  pathname: "/products-details",
                  state: { title: title, img: imageGallery },
                }}
              >
                <div className="product-card__image-container">
                  <div className="product-card__image-name-overlay">
                    <p>{imgThreeName}</p>
                  </div>
                  <div className="product-card__my-image">
                    <img src={imgThree} alt=" Three" />
                  </div>
                </div>
              </Link>
            </div>
          </Carousel.Item>

          {imgFour && imgFive && imgSix && (
            <Carousel.Item interval={2000}>
              <div className="product-card__main-content">
                <Link
                  to={{
                    pathname: "/products-details",
                    state: { title: title, img: imageGallery },
                  }}
                >
                  <div className="product-card__image-container">
                    <div className="product-card__image-name-overlay">
                      <p>{imgFourName}</p>
                    </div>
                    <div className="product-card__my-image">
                      <img src={imgFour} alt=" Four" />
                    </div>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: "/products-details",
                    state: { title: title, img: imageGallery },
                  }}
                >
                  <div className="product-card__image-container">
                    <div className="product-card__image-name-overlay">
                      <p>{imgFiveName}</p>
                    </div>
                    <div className="product-card__my-image">
                      <img src={imgFive} alt=" Five" />
                    </div>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: "/products-details",
                    state: { title: title, img: imageGallery },
                  }}
                >
                  <div className="product-card__image-container">
                    <div className="product-card__image-name-overlay">
                      <p>{imgSixName}</p>
                    </div>
                    <div className="product-card__my-image">
                      <img src={imgSix} alt=" Six" />
                    </div>
                  </div>
                </Link>
              </div>
            </Carousel.Item>
          )}

          {imgSeven && imgEight && imgNine && (
            <Carousel.Item interval={2000}>
              <div className="product-card__main-content">
                <Link
                  to={{
                    pathname: "/products-details",
                    state: { title: title, img: imageGallery },
                  }}
                >
                  <div className="product-card__image-container">
                    <div className="product-card__image-name-overlay">
                      <p>{imgSevenName}</p>
                    </div>
                    <div className="product-card__my-image">
                      <img src={imgSeven} alt=" Seven" />
                    </div>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: "/products-details",
                    state: { title: title, img: imageGallery },
                  }}
                >
                  <div className="product-card__image-container">
                    <div className="product-card__image-name-overlay">
                      <p>{imgEightName}</p>
                    </div>
                    <div className="product-card__my-image">
                      <img src={imgEight} alt=" Eight" />
                    </div>
                  </div>
                </Link>
                <Link
                  to={{
                    pathname: "/products-details",
                    state: { title: title, img: imageGallery },
                  }}
                >
                  <div className="product-card__image-container">
                    <div className="product-card__image-name-overlay">
                      <p>{imgNineName}</p>
                    </div>
                    <div className="product-card__my-image">
                      <img src={imgNine} alt=" Nine" />
                    </div>
                  </div>
                </Link>
              </div>
            </Carousel.Item>
          )}
        </Carousel>
      </div>
    </div>
  );
};
