import { Carousel } from "react-bootstrap";

export const Partners = () => {
  return (
    <section className="partners">
      <div className="partners-container">
        <div className="partners__title">
          <p>
            We have partnered with <b>50+ clients</b> to provide our services
          </p>
        </div>
        <div className="partners__carousel">
          <Carousel>
            <Carousel.Item interval={2000}>
              <div className="partners__main-content">
                <div className="partners__image-container">
                  <img src="/assets/img/accenture.png" alt="Accenture"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/colive.svg" alt="Colive"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/cybrilla.png" alt="Cybrilla"></img>
                </div>
                <div className="partners__image-container">
                  <img
                    src="/assets/img/euro_school.png"
                    alt="Euro School"
                  ></img>
                </div>
                <div className="partners__image-container">
                  <img
                    src="/assets/img/everest_fleet.png"
                    alt="Everest Fleet"
                  ></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/ibm.svg" alt="IBM"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/indorama.png" alt="Indorama"></img>
                </div>
                <div className="partners__image-container">
                  <img
                    src="/assets/img/itc_limited.png"
                    alt="ITC Limited"
                  ></img>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <div className="partners__main-content">
                <div className="partners__image-container">
                  <img src="/assets/img/jupiter.png" alt="Jupiter"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/legato.png" alt="Legato"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/lululemon.png" alt="Lululemon"></img>
                </div>
                <div className="partners__image-container">
                  <img
                    src="/assets/img/morgan_stanley.png"
                    alt="Morgan Stanley"
                  ></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/mpl.png" alt="MPL"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/narayana.png" alt="Narayana"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/swiggy.svg" alt="Swiggy"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/tekion.png" alt="Tekion"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/wipro.png" alt="Wipro"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/zolo.png" alt="Zolo"></img>
                </div>
                <div className="partners__image-container">
                  <img src="/assets/img/zomato.png" alt="Zomato"></img>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </section>
  );
};
