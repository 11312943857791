import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { CustomNavbar } from "../../components/Navbar/CustomNavbar";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { Footer } from "../../components/Footer/Footer";

function Faq() {
  const faqs = [
    {
      question: "How do you maintain the hygiene of InfinityBoxes?",
      answer:
        "We guarantee the cleanliness of boxes by making use of a process similar to that followed in 5 stars and also ensure close supervision. Each box after being washed thoroughly will also pass 4 quality checks before being sent to restaurants for reuse- \n 1) No odor \n 2) No residue \n 3) Clean from inside and outside \n 4) Industrial Grade Sanitization \n 5) No Plastic Leaching",
    },
    {
      question: "How do I return InfinityBoxes?",
      answer:
        "Through the help of various food aggregators like Swiggy, Zomato, UberEATS, etc., we will deliver InfinityBoxes to their partner restaurants. After that, the only change that will affect the customers is an option on their food delivery app which will allow them to opt for InfinityBoxes instead of single-use plastic containers. After the food has been delivered, users will be incentivized by lower packaging fees to return the containers and will have 4 ways to return them: \n 1. Return it back to the delivery person just after the food has been delivered \n 2. Return it back to the delivery person when they order food the next time \n 3. Return it to the delivery person by scheduling a pick-up from their house/office \n 4. Place it in the containers in strategically placed smart bins across the city",
    },
    {
      question: "How is InfinityBox helping the environment?  ",
      answer:
        "One InfinityBox used 40 times creates 10 times LESS green house gas emissions compared to any other single use container be it paper, glass, plastic, anything!",
    },
    {
      question: "Why are they made out of Plastic?",
      answer:
        "While by no means perfect, PP plastic is lightweight, durable, and recyclable. InfinityBoxes need to be light so we can pick up and deliver them. They need to be durable so we can wash and reuse them as many times as possible. And they need to be a size and shape that vendors are familiar with serving their food in. Since another material that fulfills all these criteria isn’t available at this time, we decided to go with a plastic container. We make sure each container is recycled at the end of its life.",
    },
    {
      question: "Doesn't it take a whole lot of energy to wash InfinityBoxes?",
      answer:
        "It does require water and energy to wash InfinityBoxes. However, even with the energy and water needed to wash, the overall environmental impact is substantially less than single-use, throw-away containers in terms of water and energy consumed, Greenhouse gases produced and waste dumped into to the landfills.",
    },
    {
      question: "Do I have to clean the container before I return it?",
      answer:
        "You do not need to wash or rinse the container before returning it, but please make sure it’s as free of food and liquid as possible. If you have the ability to easily give it a quick rinse, that’s great, but not necessary.",
    },
    {
      question:
        "How are InfinityBox plates more sustainable than a bagasse/ non-single-use plastic plate?",
      answer:
        "Reusable containers are the most environmentally friendly containers and emit 10 times lesser greenhouse gas emissions compared to any other container including biodegradable ones. Moreover, they drastically reduce the amount of waste being dumped into landfills.The reason is simple- reusing an existing plate means no virgin resources being used to make new plates, even if they are biodegradable",
    },
    {
      question:
        "How do you clean these plates? How can we be assured of the hygiene?",
      answer:
        "InfinityBox follows a TUV lab certified process to ensure top notch hygiene for its clients. They get multiple certifications to clean their packaging: \n • Migration testing to ensure plastic is not leaching onto the food \n • Anti-microbial test to ensure no bacteria or other micro-organism is present in the containers post-washing \n • Hard metal testing to ensure there are no hard metals in the packaging used",
    },

    {
      question: "What are these plates made out of?",
      answer:
        "InfinityBox offers its services using polypropylene plates. It is food grade and the same material used to make tupperware containers.",
    },
    {
      question: "What happens to these plates at their end of life?",
      answer:
        "InfinityBox sells these plates to recyclers to ensure a truly circular process",
    },
    {
      question: "Who are some of the other clients of InfinityBox",
      answer:
        "InfinityBox is India's largest sustainable packaging service. They work with Swiggy, Zomato, Morgan Stanley, Indorama, Narayana Hospitals, Lulu Lemon, and several other marquee clients",
    },
  ];

  return (
    <div className="faq">
      <CustomNavbar />
      <div className="faq__gallery-img-container">
        <img src="/assets/img/faq-pic.png" alt="faq"></img>
      </div>
      <div className="faq__container">
        <FaqTab faq={faqs[0]} />
        <FaqTab faq={faqs[1]} />
        <FaqTab faq={faqs[2]} />
        <FaqTab faq={faqs[3]} />
        <FaqTab faq={faqs[4]} />
        <FaqTab faq={faqs[5]} />
        <FaqTab faq={faqs[6]} />
        <FaqTab faq={faqs[7]} />
        <FaqTab faq={faqs[8]} />
        <FaqTab faq={faqs[9]} />
        <FaqTab faq={faqs[10]} />
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
}

const FaqTab = ({ faq }) => {
  const [visible, setVisible] = useState(false);
  return (
    <div className="faq__tab" onClick={() => setVisible(!visible)}>
      <div className="faq__heading-row">
        <h3 className="faq__title">{faq.question}</h3>
        <div className={`faq__icon ${visible ? "faq__icon-rotated" : ""}`}>
          <FontAwesomeIcon icon={faPlus} />
        </div>
      </div>
      <div
        className={`faq__description ${
          !visible ? "faq__description-hidden" : ""
        }`}
      >
        {faq.answer}
      </div>
    </div>
  );
};

export default Faq;
