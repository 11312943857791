import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { ContactUs } from "../../components/ContactUs/ContactUs";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { Impact } from "../../components/Impact/Impact";
import { Media } from "../../components/Media/Media";
import { Partners } from "../../components/Partners/Partners";
import { Testimonials } from "../../components/Testimonials/Testimonials";
import { Vision } from "../../components/Vision/Vision";
import { WhoDoWeServe } from "../../components/WhoDoWeServe/WhoDoWeServe";

export const HomeScreen = () => {
  return (
    <div className="homescreen">
      <Header />
      <Vision />
      <Impact />
      <Partners />
      <WhoDoWeServe />
      <ProductsCarousel />

      <Media />
      <Testimonials />
      <ContactUs />
      <Footer />
    </div>
  );
};

const ProductsCarousel = () => {
  return (
    <>
      <h2 className="packaging-products__heading">Our Products</h2>
      <Carousel>
        <Carousel.Item>
          <div className="packaging-products__carousel">
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img
                  src="/assets/img/acrylic-1.jpeg"
                  alt="Acrylic Product 1"
                ></img>
              </div>
            </Link>
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img
                  src="/assets/img/bone-china-1.jpeg"
                  alt="Bone China Product 1"
                ></img>
              </div>
            </Link>
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img
                  src="/assets/img/chefen-1.jpeg"
                  alt="Chefen Product 1"
                ></img>
              </div>
            </Link>
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img
                  src="/assets/img/melamine-1.jpeg"
                  alt="Melamine Product 1"
                ></img>
              </div>
            </Link>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="packaging-products__carousel">
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img
                  src="/assets/img/acrylic-2.jpeg"
                  alt="Acrylic Product 2"
                ></img>
              </div>
            </Link>
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img
                  src="/assets/img/bone-china-2.jpeg"
                  alt="Bone China Product 2"
                ></img>
              </div>
            </Link>
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img
                  src="/assets/img/acrylic-3.jpeg"
                  alt="Acrylic Product 3"
                ></img>
              </div>
            </Link>
            <div className="packaging-products__carousel-img">
              <Link to="products">
                <img
                  src="/assets/img/melamine-2.jpeg"
                  alt="Melamine Product 2"
                ></img>
              </Link>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="packaging-products__carousel">
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img
                  src="/assets/img/acrylic-4.jpeg"
                  alt="Acrylic Product 4"
                ></img>
              </div>
            </Link>
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img
                  src="/assets/img/chefen-2.jpeg"
                  alt="Chefen Product 2"
                ></img>
              </div>
            </Link>
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img src="/assets/img/box-2.jpg" alt="Box Product 2"></img>
              </div>
            </Link>
            <Link to="products">
              <div className="packaging-products__carousel-img">
                <img src="/assets/img/box.jpg" alt="Box Product"></img>
              </div>
            </Link>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
};
